import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

const Slider = () => {
  const [isPlay, setIsPlaying] = useState(3000);

  const handlePause = () => {
    setIsPlaying(3000);
    console.log("pause", isPlay);
  };
  const handlePlay = () => {
    setIsPlaying(null);
    console.log("play", isPlay);
  };
  return (
    <section className="banner">
      <div className="carousel-container">
        <Carousel touch={true} controls={true} interval={isPlay}>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./assets/images/Banner_02.jpeg"
              alt="Second slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./assets/images/Banner_03.jpeg"
              alt="Third slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./assets/images/Bannerr_04.jpeg"
              alt="Fourth slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./assets/images/Banner_05.jpeg"
              alt="Fifth slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="./assets/images/Banner_06.jpeg"
              alt="Sixth slide"
            />
          </Carousel.Item>
        </Carousel>
        {isPlay !== null ? (
          <span className="play-icon" onClick={handlePlay}></span>
        ) : (
          <span className="pause-icon" onClick={handlePause}></span>
        )}
      </div>
    </section>
  );
};

export default Slider;
